import { useVirtualizer } from "@tanstack/react-virtual";
import { areIntervalsOverlapping } from "date-fns";
import { first, isDate, isNumber, last } from "lodash";
import { RefObject, useMemo, useCallback, useEffect } from "react";

export type TimelineUnit = {
    ceil: (date: Date | number) => Date;
    floor: (date: Date | number) => Date;
    add: (date: Date | number, amount: number) => Date;
    dist: (a: Date | number, b: Date | number) => number;
};

export const roundToUnit = (interval: Interval, unit: TimelineUnit) => {
    // We would like to know if the 2 dates are equal depending on the unit;
    const isEqual = (a: Date | number, b: Date | number) => Math.abs(unit.dist(a, b)) === 0;

    // The start is the first unit just after the interval start
    let start = unit.floor(interval.start);
    start = isEqual(start, interval.start) ? start : unit.add(start, 1);

    // The end is the first unit just before the interval end
    let end = unit.ceil(interval.end);
    end = isEqual(end, interval.end) ? end : unit.add(end, -1);

    return {
        start,
        end,
    };
};

interface Options<T> {
    viewportRef: RefObject<T>;
    unit: TimelineUnit;
    min: Date | number;
    max: Date | number;
    unitLength: number;
}

export const useTimeline = <T extends HTMLElement>({ viewportRef, unit, min, max, unitLength }: Options<T>) => {
    const options = useMemo(() => {
        const bounds = roundToUnit(
            {
                start: min,
                end: max,
            },
            unit,
        );

        return {
            getScrollElement: () => viewportRef.current,
            estimateSize: () => unitLength,
            horizontal: true,
            count: Math.abs(unit.dist(bounds.end, bounds.start)) + 1,
            bounds,
            overscan: 30,
        };
    }, [unit, min, max, viewportRef, unitLength]);

    const { count, bounds } = options;

    const virtualizer = useVirtualizer<T, any>(options); // Any as we do not use items value

    // Invalidate previous measurement cache on options change
    useEffect(() => {
        virtualizer.measure();
    }, [options]);

    const getDistance = useCallback(
        (interval: Interval | Date | number) => {
            const d = (date: Date | number) => {
                const origin = bounds.start.getTime();

                const one = unit.add(bounds.start, 1).getTime() - origin;
                const x = new Date(date).getTime() - origin;

                return Math.floor((x * unitLength) / one);
            };

            return Math.abs(isNumber(interval) || isDate(interval) ? d(interval) : d(interval.end) - d(interval.start));
        },
        [unit, unitLength, bounds],
    );

    const getDate = useCallback(
        (position: number) => {
            const origin = bounds.start.getTime();
            const one = unit.add(bounds.start, 1).getTime() - origin;

            // origin => 0;
            // one => unitLength
            // date => position
            // date = ((position * one) / unitLength) + origin

            return new Date(Math.floor((position * one) / unitLength) + origin);
        },
        [unit.add, unitLength, bounds.start],
    );

    const round = useMemo(() => {
        function round(date: Date | number): Date;
        function round(interval: Interval): Interval;
        function round(value: any): any {
            if (isNumber(value) || isDate(value)) {
                return unit.floor(value);
            }

            return {
                start: unit.floor(value.start),
                end: unit.ceil(value.end),
            };
        }

        return round;
    }, [unit, unitLength, bounds]);

    const getInterval = useCallback(
        (overscan?: boolean) => {
            const items = virtualizer.getVirtualItems();
            const firstItem = first(items);
            const lastItem = last(items);
            const origin = unit.floor(min);

            if (!firstItem || !lastItem) {
                return undefined;
            }

            if (!overscan) {
                const scroll = options.getScrollElement();

                if (!scroll) {
                    return undefined;
                }

                const length = options.horizontal ? scroll.clientWidth : scroll.clientHeight;
                const position = options.horizontal ? scroll.scrollLeft : scroll.scrollTop;

                return {
                    start: unit.floor(getDate(position)),
                    end: unit.ceil(getDate(position + length)),
                };
            }

            return {
                start: unit.add(origin, firstItem.index),
                end: unit.ceil(unit.add(origin, lastItem.index)),
            };
        },
        [virtualizer, unit, min, count, options.getScrollElement, getDate],
    );

    const isVisible = useCallback(
        (value: Interval | Date | number, overscan?: boolean) => {
            const interval = getInterval(overscan);
            if (!interval) {
                return false;
            }

            return areIntervalsOverlapping(
                isNumber(value) || isDate(value)
                    ? {
                          start: value,
                          end: value,
                      }
                    : value,
                interval,
            );
        },
        [getInterval],
    );

    return {
        ...virtualizer,
        /**
         * Unit length in px
         */
        unitLength,
        /**
         * Timeline dates range interval
         */
        bounds,
        /**
         * Round date or interval according to timeline unit
         */
        round,
        /**
         * Get virtualized visible dates interval
         */
        getInterval,
        /**
         * Get Distance in px between two dates
         */
        getDistance,
        /**
         * Get unit rounded Date from position
         */
        getDate,
        /**
         * Return whether a date or an interval should be visible
         */
        isVisible,
    };
};
