import { AnimatePresence, motion } from "framer-motion";
import { DetailedHTMLProps, HTMLAttributes, SyntheticEvent, useMemo, useState } from "react";
import { useId } from "react-aria";
import { useTranslation } from "react-i18next";
import ApiErrorAlert from "src/components/ApiErrorAlert";
import { usePlaceProfessionalSituation } from "src/components/professional-situations/usePlaceProfessionalSituation";
import { SROnly, Text } from "src/uikit";
import { Radio } from "src/uikit/Radio";
import styled from "styled-components";

import { useRestrictedScope } from "@sol/access-control";
import { Feature, useCheckFeatureFlag } from "@sol/features";
import { Arrow, Chevron, Loader, Loading, Skill } from "@sol/icons";
import { Route, useRouter } from "@sol/routing";
import { getResourceUuid, IProfessionalSituationResource, useDeleteProfessionalSituation } from "@sol/sdk";
import { Button, Grid, GridColumn, IconButton } from "@sol/uikit";
import { ActionMenu, ActionMenuItem } from "@sol/uikit/collections/ActionMenu";

import BlurredModal, { BlurredModalContainer } from "../BlurredModal";
import UserBox from "../UserBox";
import { PROFESSIONAL_SITUATION_EDIT, ProfessionalSituationPickerContext } from "./ProfessionalSituationsPicker";

type ProfessionalSituationItem = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
    isBriefCompatible: boolean;
    isSelected: boolean;
};

const Card = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    padding: ${({ theme }) => theme.spacing[7]};
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
`;

const Heading = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    --text-color: ${({ theme }) => theme.palette.purple.base};

    & > div {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing[6]};

        ${Radio}, ${Text} {
            cursor: pointer;
        }
    }
`;

const SubHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
    }
`;

const CountryFlag = styled.img`
    height: 24px;
    width: 24px;
    margin-right: ${({ theme }) => theme.spacing[5]};
    margin-left: ${({ theme }) => theme.spacing[2]};
`;

const SkillItem = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: 56px;
    max-height: 56px;
    padding: ${({ theme }) => theme.spacing[5]};
`;

const SkillItemIconBox = styled.div`
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.purple.lightest};
    margin-right: ${({ theme }) => theme.spacing[5]};

    --icon-color: ${({ theme }) => theme.palette.purple.base};
`;

const SkillsCount = styled.span`
    color: ${({ theme }) => theme.palette.grey.base};
`;

const ExpandedContent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[6]};
`;

const Footer = styled(motion.footer)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: ${({ theme }) => theme.palette.white.base};
    padding: ${({ theme }) => theme.spacing[3]};
    border-radius: ${({ theme }) => `0 0 ${theme.shape.borderRadius.large} ${theme.shape.borderRadius.large}`};

    ${Button}:first-of-type {
        border: 1px solid ${({ theme }) => theme.palette.white.base};
    }

    ${Button}:disabled {
        cursor: not-allowed;
        pointer-events: all;
    }
`;

const DeleteModal = styled(BlurredModal)`
    > ${BlurredModalContainer} {
        padding: ${({ theme }) => theme.spacing[9]};
        margin: ${({ theme }) => `${theme.spacing[5]} auto`};
        width: 764px;
    }
`;

const Actions = styled.div`
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing[7]};
    margin-top: ${({ theme }) => theme.spacing[10]};
    width: 100%;
`;

const Error = styled(Text)`
    color: ${({ theme }) => theme.palette.red.base};
`;
// palette['pruple'][x ? 'light' : 'base']
const Item = styled.article<ProfessionalSituationItem>`
    background-color: ${({ isBriefCompatible, isSelected, theme }) =>
        isSelected && isBriefCompatible
            ? theme.palette.purple.base
            : isSelected && !isBriefCompatible
            ? theme.palette.purple.light
            : theme.palette.white.base};
    border: 1px solid
        ${({ isBriefCompatible, isSelected, theme }) =>
            isSelected && isBriefCompatible
                ? theme.palette.purple.base
                : isSelected && !isBriefCompatible
                ? theme.palette.purple.light
                : theme.palette.white.base};
`;

// TODO: improve animation (weird glitch)
const ANIMATION_PROPS = {
    layout: true,
    initial: { opacity: 0, height: 0 },
    animate: { opacity: 1, height: "auto" },
    exit: { opacity: 0, height: 0 },
    transition: { duration: 0.3 },
};

type Props = {
    professionalSituation: IProfessionalSituationResource;
    onSelect: (professionalSituation: IProfessionalSituationResource) => void;
    onDelete: () => void;
    onCreateBriefClick?: (professionalSituation: IProfessionalSituationResource) => void;
    isSelected: boolean;
    className?: string;
    briefSkills?: string[];
    briefLanguage?: string;
    isEdit?: boolean;
    showCreateBriefButton?: boolean;
    showPlaceBriefButton?: boolean;
    context?: ProfessionalSituationPickerContext;
};

const ProfessionalSituationItem = ({
    professionalSituation,
    isSelected,
    onSelect,
    onCreateBriefClick,
    onDelete,
    className,
    briefSkills,
    briefLanguage,
    isEdit,
    showCreateBriefButton,
    showPlaceBriefButton,
    context,
}: Props) => {
    const {
        title,
        "@id": id,
        createdAt,
        createdBy,
        language,
        targetedDomain,
        problematic,
        description,
        skills,
    } = professionalSituation;

    const [t] = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isBriefSkillsCompatible, setIsBriefSkillsCompatible] = useState(true);
    const [isBriefLanguageCompatible, setIsBriefLanguageCompatible] = useState(true);

    const { deleteProfessionalSituation, isLoading: isDeleteLoading } = useDeleteProfessionalSituation({
        onSuccess: () => onDelete(),
    });

    const router = useRouter();
    const scope = useRestrictedScope();
    const checkFeatureFlag = useCheckFeatureFlag();

    const canUpdate = checkFeatureFlag([Feature.PROFESSIONAL_SITUATION_UPDATE], {
        scope: scope(professionalSituation),
    });

    const canDelete = checkFeatureFlag([Feature.PROFESSIONAL_SITUATION_DELETE], {
        scope: scope(professionalSituation),
    });

    const professionalSituationId = getResourceUuid(professionalSituation);

    const placeProfessionalSituation = usePlaceProfessionalSituation();

    const labelId = useId();

    const menuOptions = useMemo(() => {
        const options: ActionMenuItem[] = [];

        if (canUpdate) {
            options.push({
                key: "edit",
                action: () => {
                    router.push({
                        route: Route.PROFESSIONAL_SITUATION_EDIT,
                        query: { id: professionalSituationId },
                        state: {
                            [PROFESSIONAL_SITUATION_EDIT]: {
                                context,
                                isEdit: true,
                            },
                        },
                    });
                },
                label: t("component.shared.ProfessionalSituationItem.edit"),
            });
        }

        if (canDelete) {
            options.push({
                key: "delete",
                action: () => setShowModal(true),
                label: t("component.shared.ProfessionalSituationItem.delete"),
            });
        }

        return options;
    }, [setShowModal, canUpdate, canDelete, professionalSituationId, t]);

    // Check if brief skills and language is compatible with professional situation
    const handleChange = () => {
        onSelect(professionalSituation);
        if (briefLanguage && briefSkills) {
            const mappedSkills = skills.map(skill => skill["@id"]);
            setIsBriefSkillsCompatible(briefSkills.every(skill => mappedSkills.includes(skill)));
            setIsBriefLanguageCompatible(language?.["@id"] === briefLanguage);
        }
    };

    return (
        <Item
            className={className}
            isBriefCompatible={isBriefSkillsCompatible && isBriefLanguageCompatible}
            isSelected={isSelected}
        >
            <Card data-cy={`${expanded ? "expanded" : "shrinked"}-professional-situation`}>
                <Heading>
                    <div data-cy={`${isSelected ? "selected" : "unselected"}-professional-situation`}>
                        <Radio
                            id={id}
                            color="purple"
                            checked={isSelected}
                            onChange={handleChange}
                            title={`${t("component.shared.ProfessionalSituationItem.selectTitle")} - ${title}`}
                        />
                        <Text
                            data-cy="professional-situation-item-title"
                            id={labelId}
                            variant="h4"
                            as="label"
                            htmlFor={id}
                        >
                            {title}
                        </Text>
                    </div>
                    <IconButton
                        type="button"
                        variant="secondary"
                        onClick={() => setExpanded(!expanded)}
                        data-cy={`${expanded ? "shrink" : "expand"}-professional-situation-button`}
                    >
                        <Chevron direction={expanded ? "up" : "down"} />
                        <SROnly>
                            {t(`component.shared.ProfessionalSituationItem.${expanded ? "expand" : "shrink"}SrOnly`, {
                                title,
                            })}
                        </SROnly>
                    </IconButton>
                </Heading>
                <SubHeading>
                    <UserBox type="createdAt" date={createdAt} user={createdBy} />
                    <div>
                        <Text variant="label">{language.locale.toUpperCase()}</Text>
                        <CountryFlag
                            alt=""
                            src={`/static/flags/${language.locale.substring(0, 2)}.svg`}
                            onError={(e: SyntheticEvent<HTMLImageElement, Event>) =>
                                (e.currentTarget.style.display = "none")
                            }
                        />
                        {menuOptions.length > 0 && (
                            <ActionMenu
                                label={t("page.evaluation.classroomEvaluation.byPeriod.menuOptions.label")}
                                aria-describedby={labelId}
                                data-cy="professional-situation-menu-button"
                                variant="secondary"
                                placement="left top"
                                items={menuOptions}
                            />
                        )}
                    </div>
                </SubHeading>
                <div>
                    <Text variant="label">{t("component.shared.ProfessionalSituationItem.targetedDomain")}</Text>
                    <Text data-cy="professional-situation-item-targeted-domain" lines={expanded ? undefined : 2}>
                        {targetedDomain}
                    </Text>
                </div>
                <div>
                    <Text variant="label">{t("component.shared.ProfessionalSituationItem.problematic")}</Text>
                    <Text preserve data-cy="professional-situation-item-problematic" lines={expanded ? undefined : 2}>
                        {problematic}
                    </Text>
                </div>
                <AnimatePresence>
                    {expanded && (
                        <ExpandedContent {...ANIMATION_PROPS}>
                            <div>
                                <Text variant="label">
                                    {t("component.shared.ProfessionalSituationItem.description")}
                                </Text>
                                <Text
                                    preserve
                                    data-cy="professional-situation-item-description"
                                    lines={expanded ? undefined : 2}
                                >
                                    {description}
                                </Text>
                            </div>
                            <div>
                                <Text variant="label">
                                    {t("component.shared.ProfessionalSituationItem.skills")} {" : "}
                                    <SkillsCount>{skills.length}</SkillsCount>
                                </Text>
                                <Grid as="ul">
                                    {skills.map(skill => (
                                        <GridColumn col={4} as="li" key={skill["@id"]}>
                                            <SkillItem>
                                                <SkillItemIconBox>
                                                    <Skill />
                                                </SkillItemIconBox>
                                                <Text lines={2}>
                                                    {skill.code}. {skill.officialTitle}
                                                </Text>
                                            </SkillItem>
                                        </GridColumn>
                                    ))}
                                </Grid>
                            </div>
                        </ExpandedContent>
                    )}
                </AnimatePresence>
                {!expanded && (
                    <div>
                        <Text variant="label">
                            {t("component.shared.ProfessionalSituationItem.skills")} {" : "}
                            <SkillsCount>{skills.length}</SkillsCount>
                        </Text>
                        <Grid as="ul">
                            {skills.slice(0, 3).map(skill => (
                                <GridColumn col={4} as="li" key={skill["@id"]}>
                                    <SkillItem>
                                        <SkillItemIconBox>
                                            <Skill />
                                        </SkillItemIconBox>
                                        <Text lines={2}>
                                            {skill.code}. {skill.officialTitle}
                                        </Text>
                                    </SkillItem>
                                </GridColumn>
                            ))}
                        </Grid>
                    </div>
                )}
                {isSelected && !isBriefSkillsCompatible && (
                    <Error>{t("component.shared.ProfessionalSituationItem.skillCompatible")}</Error>
                )}

                {isSelected && !isBriefLanguageCompatible && (
                    <Error>{t("component.shared.ProfessionalSituationItem.languageCompatible")}</Error>
                )}
            </Card>
            <AnimatePresence>
                {isSelected && (
                    <Footer {...ANIMATION_PROPS}>
                        {showPlaceBriefButton && (
                            <Button
                                type="button"
                                filled
                                variant="primary"
                                data-cy="place-professional-situation"
                                loading={placeProfessionalSituation.isLoading}
                                disabled={placeProfessionalSituation.disabled}
                                onClick={() => placeProfessionalSituation.mutate(professionalSituation)}
                            >
                                {t("component.shared.ProfessionalSituationItem.placeProfessionalSituation")}
                                {placeProfessionalSituation.isLoading ? <Loading /> : <Arrow direction="right" />}
                            </Button>
                        )}
                        {showCreateBriefButton && onCreateBriefClick && (
                            <Button
                                type="submit"
                                filled
                                variant="primary"
                                disabled={!(isBriefSkillsCompatible && isBriefLanguageCompatible)}
                                aria-disabled={!(isBriefSkillsCompatible && isBriefLanguageCompatible)}
                                data-cy="create-brief-from-professional-situation-button"
                                onClick={() => onCreateBriefClick(professionalSituation)}
                            >
                                {t(
                                    isEdit
                                        ? "component.shared.ProfessionalSituationItem.updateBriefProfessionalSituation"
                                        : "component.shared.ProfessionalSituationItem.createBriefFromProfessionalSituation",
                                )}
                            </Button>
                        )}
                    </Footer>
                )}
            </AnimatePresence>
            <DeleteModal visible={showModal}>
                <Text variant="h4">{t("component.shared.ProfessionalSituationItem.deleteConfirmation")}</Text>
                <Actions>
                    <Button type="button" variant="primary" onClick={() => setShowModal(false)}>
                        {t("component.shared.ProfessionalSituationItem.cancelation")}
                    </Button>
                    <Button
                        type="button"
                        filled
                        variant="primary"
                        data-cy="delete-button"
                        onClick={() => deleteProfessionalSituation(getResourceUuid(professionalSituation))}
                    >
                        {isDeleteLoading ? <Loader /> : t("component.shared.ProfessionalSituationItem.confirmation")}
                    </Button>
                </Actions>
            </DeleteModal>
            <ApiErrorAlert
                error={placeProfessionalSituation.error}
                dissmiss={() => placeProfessionalSituation.reset()}
            />
        </Item>
    );
};

export default styled(ProfessionalSituationItem)<Props>`
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    background: ${({ theme }) => theme.palette.white.base};

    ${Grid} {
        margin-top: ${({ theme }) => theme.spacing[2]};
        list-style-type: none;
        padding-left: 0;
    }
`;
