import { FocusableRef } from "@react-types/shared";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { Key } from "react-aria";
import { Merge, SetOptional } from "type-fest";

import { Menu as MenuIcon } from "@sol/uikit/core/icons";
import { IconButton } from "@sol/uikit/molecules/IconButton";

import { Menu, MenuTrigger, MenuProps, MenuItem } from "../Menu";

export type ActionMenuItem = {
    key: Key;
    label: string;
    action?: () => void;
    href?: string;
    target?: string;
};

type ActionMenuProps<T extends ActionMenuItem = ActionMenuItem> = Merge<
    Merge<ComponentPropsWithoutRef<typeof IconButton>, ComponentPropsWithoutRef<typeof MenuTrigger>>,
    SetOptional<
        Pick<MenuProps<T>, "items" | "onAction" | "disabledKeys" | "variant" | "placement" | "children">,
        "children"
    >
>;

const ActionMenu = <T extends ActionMenuItem = ActionMenuItem>(
    {
        isOpen,
        defaultOpen,
        onOpenChange,
        trigger,
        variant,
        children,
        items,
        onAction,
        disabledKeys,
        placement,
        ...props
    }: ActionMenuProps<T>,
    ref: FocusableRef<HTMLButtonElement>,
) => {
    const onActionDefault = (key: Key) => {
        items?.find(item => item.key === key)?.action?.();
    };
    return (
        <MenuTrigger {...{ isOpen, defaultOpen, onOpenChange, trigger }}>
            <IconButton ref={ref} variant={variant === "primary" ? "primary" : "secondary"} {...props}>
                <MenuIcon size={32} stroke="transparent-base" fill="purple-base" />
            </IconButton>
            <Menu
                variant={variant}
                items={items}
                onAction={onAction ?? onActionDefault}
                disabledKeys={disabledKeys}
                placement={placement}
            >
                {children ??
                    ((item: T) => {
                        // Default MenuItem render
                        const { label, key, ...props } = item;

                        return (
                            <MenuItem key={key} id={key} {...props}>
                                {label}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </MenuTrigger>
    );
};

const _ActionMenu = forwardRef(ActionMenu);

export { _ActionMenu as ActionMenu };
