import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IClassroom } from "@sol/sdk";

import { FilterOptions, makeCheckboxFilter, makeModalFilter } from "../filters";
import FrameworksPicker from "../pickers/FrameworksPicker";
import LanguagesPicker from "../pickers/LanguagesPicker";

export enum ProfessionalSituationsViewFilter {
    FRAMEWORKS = "frameworks",
    LANGUAGES = "languages",
    CREATOR_TYPE = "creatorType",
}

const getFilter = (
    filter: ProfessionalSituationsViewFilter,
    options: { t: TFunction; activeClassroom?: IClassroom } & FilterOptions,
) => {
    const { t, backlist = [], pinned, activeClassroom } = options;

    switch (filter) {
        case ProfessionalSituationsViewFilter.CREATOR_TYPE:
            return makeCheckboxFilter(filter, ["me", "others"], { t, backlist, pinned });

        case ProfessionalSituationsViewFilter.FRAMEWORKS:
            return makeModalFilter(filter, t)(FrameworksPicker, { activeClassroom });

        case ProfessionalSituationsViewFilter.LANGUAGES:
            return makeModalFilter(filter, t)(LanguagesPicker);
    }
};

export const useFilters = (
    filters: ({
        viewFilter: ProfessionalSituationsViewFilter;
        activeClassroom?: IClassroom;
    } & FilterOptions)[],
    deps: any[],
) => {
    const [t] = useTranslation(undefined, { keyPrefix: "component.AddProfessionalSituationView.filters" });

    return useMemo(
        () =>
            filters.map(filter => {
                const { viewFilter, ...options } = filter;
                return getFilter(viewFilter, { t, ...options });
            }),
        [t, ...deps],
    );
};
