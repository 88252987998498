import { AnimateSharedLayout } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BlockSkeleton } from "src/components/skeletons";
import useScroll from "src/hooks/useScroll";
import { Text } from "src/uikit";
import Pagination from "src/uikit/Pagination";
import ExtendedPager from "src/uikit/Pagination/ExtendedPager";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { CheckboxFilterValues, FiltersBar } from "@sol/filters";
import { Arrow, Plus } from "@sol/icons";
import { NotificationLevel, useNotify } from "@sol/notifications";
import { useNavigationState } from "@sol/persistance";
import { Route, useRouter } from "@sol/routing";
import { IProfessionalSituationResource, ResourceOrder, useProfessionalSituations } from "@sol/sdk";
import { Button } from "@sol/uikit";
import { SortButton } from "@sol/uikit/molecules/SortButton";

import SearchForm from "../SearchForm";
import { ProfessionalSituationsViewFilter, useFilters } from "./filters";
import ProfessionalSituationItem from "./ProfessionalSituationItem";

const CreateHelper = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const HeadingCard = styled.section`
    padding: ${({ theme }) => theme.spacing[7]};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    width: 100%;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
    list-style-type: none;
    padding: 0;
`;

const LoadingList = styled(BlockSkeleton)`
    margin-top: ${({ theme }) => theme.spacing[5]};
    background-color: ${({ theme }) => theme.palette.purple.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};
    height: 296px;
`;

const PreviousButton = styled(Button)`
    align-self: flex-start;
`;

const ActionsBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[6]};
    button:last-child {
        margin-left: auto;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export enum ProfessionalSituationPickerContext {
    PEDAGOGICAL_SCENARIO = "pedagogical-scenario",
    BRIEF = "brief",
}

const RESULTS_PER_PAGE = 8;
export const SELECTED_PROFESSIONAL_SITUATION_KEY = "selectedProfessionalSituation";
export const PROFESSIONAL_SITUATION_CREATE = "professionalSituationCreate";
export const PROFESSIONAL_SITUATION_EDIT = "professionalSituationEdit";
export const BRIEF_IS_UNDER_EDITION = "briefIsUnderEdition";

type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

const filterTruthy = function <T>(values?: T[]) {
    return (values ?? []).filter(value => !!value) as Truthy<T>[];
};

const mapFiltersValueToFilters = (filtersValue: CheckboxFilterValues) => {
    const creatorType = filterTruthy(filtersValue?.[ProfessionalSituationsViewFilter.CREATOR_TYPE]);
    const frameworks = filterTruthy(filtersValue?.[ProfessionalSituationsViewFilter.FRAMEWORKS]);
    const languages = filterTruthy(filtersValue?.[ProfessionalSituationsViewFilter.LANGUAGES]);

    const createdByMe = creatorType.includes("me");
    const createdByOthers = creatorType.includes("others");

    return {
        frameworks,
        languages,
        createdByConnectedUser: createdByMe === createdByOthers ? undefined : createdByMe,
    };
};

type Props = {
    onProfessionalSituationSelect: (professionalSituation: IProfessionalSituationResource) => void;
    className?: string;
    briefSkills?: string[];
    briefLanguage?: string;
    briefId?: string;
    isEdit?: boolean;
    professionalSituationId?: string;
    onNextCLick?: () => void;
    showCreateBriefButton?: boolean;
    showPlaceBriefButton?: boolean;
    context?: ProfessionalSituationPickerContext;
};

const defaultActiveFilters = {};

const ProfessionalSituationsPicker = ({
    onProfessionalSituationSelect,
    className,
    briefSkills,
    briefLanguage,
    briefId,
    isEdit,
    professionalSituationId,
    onNextCLick,
    showCreateBriefButton = true,
    showPlaceBriefButton = true,
    context = ProfessionalSituationPickerContext.BRIEF,
}: Props) => {
    const [t] = useTranslation();
    const { activeClassroom } = useActiveClassroom();
    const notify = useNotify();
    const router = useRouter();
    const scrollEl = useScroll();
    const { BRIEF, PEDAGOGICAL_SCENARIO } = ProfessionalSituationPickerContext;

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useNavigationState<string>("search", "");
    const [activeFilters, setActiveFilters] = useState<CheckboxFilterValues>(defaultActiveFilters);
    const [order, setOrder] = useState<ResourceOrder>("desc");
    const [selectedProfessionalSituationId, setSelectedProfessionalSituationId] = useNavigationState<string>(
        SELECTED_PROFESSIONAL_SITUATION_KEY,
        router.state?.[BRIEF_IS_UNDER_EDITION]?.professionalSituationId ||
            router.state?.[PROFESSIONAL_SITUATION_CREATE]?.professionalSituationId ||
            professionalSituationId,
    );

    const [searchValue, setSearchValue] = useState(search);
    const { handleSubmit } = useFormContext() ?? {};

    useEffect(() => {
        setCurrentPage(1);
    }, [search, activeFilters]);

    const filters = useFilters(
        [
            {
                viewFilter: ProfessionalSituationsViewFilter.FRAMEWORKS,
                activeClassroom: context === PEDAGOGICAL_SCENARIO ? activeClassroom : undefined,
            },
            { viewFilter: ProfessionalSituationsViewFilter.LANGUAGES },
            { viewFilter: ProfessionalSituationsViewFilter.CREATOR_TYPE, pinned: true },
        ],
        [],
    );

    const {
        data: professionalSituationsList,
        isLoading: isProfessionalSituationsLoading,
        refetch,
        isFetching,
    } = useProfessionalSituations({
        filters: {
            ...mapFiltersValueToFilters(activeFilters),
            title: !!search.trim() ? search : undefined,
            compatibleClassroom: context === PEDAGOGICAL_SCENARIO ? activeClassroom : undefined,
        },
        pagination: { perPage: RESULTS_PER_PAGE, page: currentPage },
        orderBy: "createdAt",
        order,
    });

    useEffect(() => {
        scrollEl?.scrollTo?.({ behavior: "smooth", top: 0 });
    }, []);

    const totalPages = Math.ceil((professionalSituationsList?.["hydra:totalItems"] ?? 0) / RESULTS_PER_PAGE);

    const onDelete = () => {
        refetch();
        notify({
            level: NotificationLevel.SUCCESS,
            message: t("component.AddProfessionalSituationView.deleteProfessionalSituationSuccess"),
        });
    };

    const onSubmit = ({
        "professional-situation": professionalSituation,
    }: {
        "professional-situation": IProfessionalSituationResource;
    }) => {
        onProfessionalSituationSelect(professionalSituation);
    };

    return (
        <div className={className}>
            <SearchForm
                searchFieldId="search"
                onSubmit={() => setSearch(searchValue)}
                onReset={() => {
                    setSearch("");
                    setSearchValue("");
                }}
                label={t("component.shared.ProfessionalSituationPicker.filters.title.label")}
                value={searchValue}
                onChange={setSearchValue}
                srOnly={t("component.shared.ProfessionalSituationPicker.filters.title.srOnly")}
                variant="secondary"
            />
            <FiltersContainer>
                <FiltersBar value={activeFilters} onChange={setActiveFilters} filters={filters} />
                <SortButton
                    data-cy="sort-button"
                    label={t("component.shared.ProfessionalSituationPicker.date")}
                    order={order}
                    onPress={() => setOrder(order === "desc" ? "asc" : "desc")}
                    isActive={order === "asc"}
                />
            </FiltersContainer>

            <HeadingCard data-cy="add-professional-situation-selection">
                <Text variant="label" as="h2">
                    {t("component.shared.ProfessionalSituationPicker.title")}
                </Text>
                <CreateHelper>{t("component.shared.ProfessionalSituationPicker.subtitle")} </CreateHelper>

                <Button
                    onClick={() =>
                        router.push({
                            route: Route.PROFESSIONAL_SITUATION_CREATE,
                            state: {
                                [PROFESSIONAL_SITUATION_CREATE]: {
                                    isEdit: isEdit,
                                    briefLanguage: briefLanguage,
                                    briefSkills: briefSkills,
                                    briefId: briefId,
                                    context: context,
                                },
                            },
                        })
                    }
                    filled
                    variant="primary"
                    data-cy="professional-situation-form-link"
                    type="button"
                >
                    {t("component.shared.ProfessionalSituationPicker.cta")} <Plus />
                </Button>
            </HeadingCard>

            {isProfessionalSituationsLoading || isFetching ? (
                <LoadingList />
            ) : context === BRIEF ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="professional-situation"
                        defaultValue=""
                        render={({ field: { value, onChange } }) => (
                            <>
                                <div aria-live="polite">
                                    <p className="sr-only">
                                        {t("component.shared.ProfessionalSituationPicker.resultsCount", {
                                            count: professionalSituationsList?.["hydra:member"].length,
                                        })}
                                    </p>
                                </div>
                                <List>
                                    <AnimateSharedLayout>
                                        {professionalSituationsList?.["hydra:member"].map(professionalSituation => {
                                            const { "@id": id } = professionalSituation;
                                            const isSelected = value?.["@id"] === id;

                                            return (
                                                <li key={id}>
                                                    <ProfessionalSituationItem
                                                        professionalSituation={professionalSituation}
                                                        onSelect={onChange}
                                                        onCreateBriefClick={professionalSituation =>
                                                            onChange(professionalSituation)
                                                        }
                                                        onDelete={onDelete}
                                                        isSelected={isSelected}
                                                        briefSkills={briefSkills}
                                                        briefLanguage={briefLanguage}
                                                        isEdit={isEdit}
                                                        showCreateBriefButton={showCreateBriefButton}
                                                        showPlaceBriefButton={showPlaceBriefButton}
                                                        context={context}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </AnimateSharedLayout>
                                </List>
                            </>
                        )}
                    />
                </form>
            ) : (
                <List>
                    <AnimateSharedLayout>
                        {professionalSituationsList?.["hydra:member"].map(professionalSituation => {
                            const { "@id": id } = professionalSituation;
                            const isSelected = selectedProfessionalSituationId === id;
                            return (
                                <li key={professionalSituation["@id"]}>
                                    <ProfessionalSituationItem
                                        professionalSituation={professionalSituation}
                                        onSelect={selectedProfessionalSituation =>
                                            setSelectedProfessionalSituationId(selectedProfessionalSituation["@id"])
                                        }
                                        isSelected={isSelected}
                                        briefSkills={briefSkills}
                                        briefLanguage={briefLanguage}
                                        isEdit={isEdit}
                                        showCreateBriefButton={showCreateBriefButton}
                                        showPlaceBriefButton={showPlaceBriefButton}
                                        onDelete={onDelete}
                                        context={context}
                                    />
                                </li>
                            );
                        })}
                    </AnimateSharedLayout>
                </List>
            )}
            {totalPages > 1 && (
                <Pagination
                    current={currentPage}
                    onChange={setCurrentPage}
                    total={totalPages}
                    pager={props => <ExtendedPager {...props} />}
                />
            )}
            <ActionsBox>
                {!isEdit && (
                    <Link href={Route.HOME} passHref>
                        <PreviousButton as="a" variant="primary" data-cy="previous-button">
                            <Arrow direction="left" />
                            {t("component.shared.ProfessionalSituationPicker.previousStep")}
                        </PreviousButton>
                    </Link>
                )}
                {isEdit && BRIEF && (
                    <Button type="button" filled variant="primary" onClick={onNextCLick}>
                        {t("component.shared.AddBriefSkillLevelsView.nextStep")}
                        <Arrow direction="right" />
                    </Button>
                )}
            </ActionsBox>
        </div>
    );
};

export default styled(ProfessionalSituationsPicker)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[5]};
`;
