import { isEqual, isBefore, isAfter } from "date-fns";
import { last } from "lodash";
import { useMemo } from "react";

import { useActiveClassroom } from "@sol/classrooms";
import { Feature } from "@sol/features";
import { Route, useRouter } from "@sol/routing";
import {
    useEducationalScenario,
    parseDate,
    useCreateEducationalScenarioItemMutation,
    getResourceUuid,
    ICreateEducationalScenarioItemInput,
} from "@sol/sdk";

import { DAY, HALF_DAY } from "../home/PedagogicalScenarioCard/Timeline/units";
import { roundToUnit } from "../home/PedagogicalScenarioCard/Timeline/useTimeline";

export const DEFAULT_DURATION = 30 - 1; // days - 1 as we start from 0;

export type Slot = { startDate: string; endDate: string };

export const getSlots = (startDate: string, endDate: string, slots: Slot[]) => {
    const result = [];

    // slots are ordered
    // we can build availables slots by taking
    // the end of previous slot as the start
    // and the start of next slot as the end

    const boundaries = roundToUnit(
        {
            start: parseDate(startDate),
            end: DAY.ceil(parseDate(endDate)),
        },
        DAY,
    );

    let start = boundaries.start;

    slots.forEach(slot => {
        const end = HALF_DAY.ceil(HALF_DAY.add(parseDate(slot.startDate), -1));

        result.push({
            start,
            end,
        });

        start = HALF_DAY.floor(HALF_DAY.add(parseDate(slot.endDate), 1));
    });

    result.push({
        start,
        end: boundaries.end,
    });

    return result.filter(slot => HALF_DAY.dist(slot.end, slot.start) > 0);
};

export const resizeSlots = (slots: { start: Date; end: Date }[]) => {
    return slots.map(slot => {
        if (DAY.dist(slot.end, slot.start) <= DEFAULT_DURATION) {
            return slot;
        }

        return {
            start: slot.start,
            end: DAY.ceil(DAY.add(slot.start, DEFAULT_DURATION)),
        };
    });
};

export const filterSlots = (slots: { start: Date; end: Date }[], after?: Date) => {
    return slots.filter(slot => {
        if (after && !isAfter(slot.start, after)) {
            return false;
        }

        return !isEqual(slot.end, slot.start) && !isBefore(slot.end, slot.start);
    });
};

export const usePlaceProfessionalSituation = () => {
    const router = useRouter();
    const { activeClassroom } = useActiveClassroom();
    const { data: educationalScenario } = useEducationalScenario({
        classroom: activeClassroom,
        filters: {
            scale: "day",
        },
    });

    const slots = useMemo(() => {
        if (!activeClassroom || !educationalScenario) {
            return [];
        }

        const { startDate, endDate } = activeClassroom;

        const educationalScenarioSlots = educationalScenario["hydra:member"] ?? [];
        // As we want to have the latest slot we do not need intermediate slots
        const lastSlot = last(educationalScenarioSlots);

        let result = getSlots(
            startDate,
            // include endDate in as available
            endDate,
            lastSlot ? [lastSlot] : [],
        );

        result = resizeSlots(result);

        return filterSlots(result, lastSlot && parseDate(lastSlot.endDate));
    }, [activeClassroom, educationalScenario]);

    const mutation = useCreateEducationalScenarioItemMutation({
        onSuccess({ data }, { start }) {
            router.push({
                route: Route.HOME,
                state: {
                    anchor: Feature.HOMEPAGE_SCENARIO_CARD,
                    [Feature.HOMEPAGE_SCENARIO_CARD]: {
                        timestamp: start,
                        educationalScenarioItemId: getResourceUuid(data),
                        scale: "day",
                    },
                },
            });
        },
    });

    return {
        ...mutation,
        mutate: (professionalSituation: ICreateEducationalScenarioItemInput["professionalSituation"]) => {
            const slot = last(slots);
            if (!activeClassroom || !slot) {
                return Promise.reject();
            }

            return mutation.mutate({
                professionalSituation,
                classroom: activeClassroom,
                ...slot,
            });
        },
        disabled: !activeClassroom || !last(slots),
    };
};
