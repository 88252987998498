import {
    differenceInCalendarWeeks,
    differenceInCalendarMonths,
    addMonths,
    differenceInCalendarDays,
    startOfMonth,
    endOfMonth,
    addWeeks,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    addDays,
    differenceInHours,
    getHours,
    addHours,
    endOfHour,
} from "date-fns";

import { TimelineUnit } from "./useTimeline";

const startOfHalfDay = (date: Date | number) => {
    const hours = getHours(date);
    const start = startOfDay(date);

    return hours < 12 ? start : addHours(start, 12);
};

export type Scale = "month" | "week" | "day";

export const MONTH: TimelineUnit = {
    floor: startOfMonth,
    ceil: endOfMonth,
    add: addMonths,
    dist: differenceInCalendarMonths,
};

export const WEEK: TimelineUnit = {
    floor: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
    ceil: (date: Date) => endOfWeek(date, { weekStartsOn: 1 }),
    add: addWeeks,
    dist: (dateLeft: Date, dateRight: Date) => differenceInCalendarWeeks(dateLeft, dateRight, { weekStartsOn: 1 }),
};

export const DAY: TimelineUnit = {
    floor: startOfDay,
    ceil: endOfDay,
    add: addDays,
    dist: differenceInCalendarDays,
};

export const HALF_DAY: TimelineUnit = {
    floor: startOfHalfDay,
    ceil: (date: Date | number) => endOfHour(addHours(startOfHalfDay(date), 11)),
    add: (date: Date | number, amount: number) => addHours(date, amount * 12),
    dist: (dateLeft: Date, dateRight: Date) =>
        Math.floor(differenceInHours(startOfHalfDay(dateLeft), startOfHalfDay(dateRight)) / 12),
};
